/* Базовые стили */
@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay/SFProDisplay-Regular.eot');
    src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('../fonts/SFProDisplay/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SFProDisplay/SFProDisplay-Regular.woff2') format('woff2'),
    url('../fonts/SFProDisplay/SFProDisplay-Regular.woff') format('woff'),
    url('../fonts/SFProDisplay/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay/SFProDisplay-Medium.eot');
    src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('../fonts/SFProDisplay/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SFProDisplay/SFProDisplay-Medium.woff2') format('woff2'),
    url('../fonts/SFProDisplay/SFProDisplay-Medium.woff') format('woff'),
    url('../fonts/SFProDisplay/SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay/SFProDisplay-Semibold.eot');
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('../fonts/SFProDisplay/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SFProDisplay/SFProDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/SFProDisplay/SFProDisplay-Semibold.woff') format('woff'),
    url('../fonts/SFProDisplay/SFProDisplay-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay/SFProDisplay-Bold.eot');
    src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('../fonts/SFProDisplay/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SFProDisplay/SFProDisplay-Bold.woff2') format('woff2'),
    url('../fonts/SFProDisplay/SFProDisplay-Bold.woff') format('woff'),
    url('../fonts/SFProDisplay/SFProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
/**/
html,
body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #ffffff;
}

#root{
    height: 100%;
}

div {
    margin: 0px;
    padding: 0px;
    display: block;
}

a {
    text-decoration: none;
    color: #446BF2;
}

a:hover {
    text-decoration: underline;
}

* {
    font-family: 'SF Pro Display';
    font-weight: normal;
    font-style: normal;

    outline: none;
    color: #100F32;
    font-size: 16px;
    line-height: 20px;
}

p{
    margin: 0px 0px 24px 0px;
}

p sup {
    font-size: 10px;
}

::selection {
    background: #446BF2;
    color: #ffffff;
}

::-moz-selection {
    background: #446BF2;
    color: #ffffff;
}

img {
    border: none;
}

img[src=""] {
    display: none;
}

table {
    border-collapse: collapse;
}

strong, b{
    font-weight: 600;
}
/**/
.blockline {
    position: relative;
}

.block {
    margin: 0 auto;
    width: 1200px;
    position: relative;
}

.clear {
    clear: both;
}

.center{
    text-align: center !important;
}

.left{
    text-align: left !important;
}

.right{
    text-align: right !important;
}
/* Элементы форм */
.field_tr{
    margin-left: -8px;
    margin-right: -8px;
}

.btn_tr{
    padding-top: 8px;
}

.btn_tr1{
    padding-bottom: 8px;
}

.field_tr:after{
    content: "";
    display: block;
    height: 1px;
    clear: both;
}

.field_td{
    float: left;
    padding: 0 8px 16px 8px;
    padding: 0 8px 24px 8px;
    box-sizing: border-box;
}

.field_tr:last-child .field_td{
    padding-bottom: 0px;
}

.field_td1{
    width: 100%;
}

.field_td2{
    width: 50%;
}

.field_td3{
    width: 33.33%;
}

.field_td3x2{
    width: 66.66%;
}

.field_td4{
    width: 25%;
}
/**/
.label{
    color: #344054;
    font-weight: 500;
    padding-bottom: 8px;
}

.label_count{
    font-weight: 500;
    display: inline-block;
    padding: 0px 7px 0px 7px;
    color: #446BF2;
}

.sub_label{
    font-size: 14px;
    color: #98A7BE;
    padding-bottom: 8px;
    margin-top: -5px;
}

.zv{
    color: #E93E3E;
    font-weight: 700;
    padding-left: 0px;
}

.last_label{
    font-size: 14px;
    font-weight: 500;
    margin-top: -4px;
}

.last_label_right{
    text-align: right;
}

.last_label_bold{
    font-weight: 600;
}
/**/

/**/
.field{
    display: block;
    width: 100%;
    height: 44px;
    padding: 0px 14px;
    border-radius: 12px;
    border: 1px solid #D0D5DD;
    color: #667085;
    box-sizing: border-box;
    background: none;
}

.field:hover{
    border: 1px solid #717689;
}

.field:focus{
    border: 1px solid #446BF2;
    box-shadow: 0px 0px 4px 0px #446BF2CC;
    color: #232323;
}

.field.error{
    border: 1px solid #F65353;
    box-shadow: 0px 0px 4px 0px #F65353E5;
    color: #232323;
}

.field:disabled,
.field.disabled
{
    border: 1px solid #838999;
    background: #E9EAED;
    color: #9FA4B0;
}
/**/
textarea.field{
    height: 160px;
    padding: 14px;
}

.field_td .field{
    width: 100%;
}

.field_veri_code:focus{
    border: 2px solid #446BF2;
}

::-webkit-input-placeholder {
    opacity: 1;
}

::-moz-input-placeholder {
    opacity: 1;
}

::input-placeholder {
    opacity: 1;
}

.field_ico_out{
    position: relative;
}

.field_ico_out .field{
    padding-right: 40px;
}

.field_ico{
    position: absolute;
    right: 14px;
    top: 0px;
    font-size: 0;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
}

.field_ico img{
    display: inline-block;
    vertical-align: middle;
    width: auto;
    max-height: 90%;
}

.field_ico.active {
    opacity: 0.5;
}
/* Кнопки */
.btn{
    display: block;
    height: 44px;
    padding: 0px 35px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #446BF2;
    background: #446BF2;
    color: #FFF;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

a.btn,
div.btn
{
    line-height: 42px;
    text-decoration: none !important;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn_tr .btn{
    line-height: 42px;
}

.btn:hover{
    border: 1px solid #28A8E9;
    background: #28A8E9;
}

.last_btn_label{
    color: #344054;
    text-align: center;
    padding-top: 0px;
}

.last_btn_label a{
    font-weight: 600;
}

.last_btn_label_center{
    text-align: center;
}

.last_btn_label span{
    color: #000;
    font-weight: 500;
}
/**/
.btn.disabled{
    border: 1px solid #98A7BE !important;
    background: #98A7BE !important;
    color: #FFFFFF !important;
}
/**/
.btn1{
    height: 36px;
    font-size: 14px;
    font-weight: 600;
}

a.btn1,
div.btn1
{
    line-height: 34px;
    padding: 0px 25px;
}
/**/
.btn_c1{
    background: #ebf0fd;
    border: 1px solid #ebf0fd;
    color: #082A94;
}

.btn_c1:hover{
    color: #ffffff;
}
/**/
.btn_c2{
    color: #051F6C;
    background-color: #ffffff;
}

.btn_c2:hover{
    color: #ffffff;
}
/**/
.btn_c3{
    color: #051F6C;
    background-color: #ffffff;
    border: 1px solid rgba(62, 108, 244, 0.50);
}

.btn_c3:hover{
    color: #ffffff;
}
/**/
.btn_c4{
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #082A94;
}

.btn_c4:hover{
    background: #ebf0fd;
    border: 1px solid #ebf0fd;
    color: #082A94;
}
/**/
.btn_c5{
    background: #F65353;
    border: 1px solid #F65353;

}

.btn_c5:hover{
    background: #446BF2;
    border: 1px solid #446BF2;
}
/**/
.btn_c6{
    color: #232323;
    background: #F7F8F8;
    border: 1px solid #F7F8F8;
}

.btn_c6:hover{
    color: #ffffff;
    background: #446BF2;
    border: 1px solid #446BF2;
}
/**/
.btn_c7{
    color: #98A7BE;
    background: #f4f6f8;
    border: 1px solid #f4f6f8;
}

.btn_c7:hover{
    color: #98A7BE;
    background: #f4f6f8;
    border: 1px solid #f4f6f8;
}
/**/
.field_with_btn_out{
    position: relative;
}

.field_with_btn_out:after{
    content: "";
    display: block;
    clear: both;
}

.field_with_btn_out .field{
    width: auto;
    float: left;
}

.field_with_btn_out .btn{
    width: auto;
    float: right;
}

/**/
/* Чекбокс */
.field_chb{
    display: inline-block;
    vertical-align: middle;
}

.field_chb input{
    position: absolute;
    display: block;
    width: 0;
    height: 0;
}

.field_chb label{
    display: block;
    box-sizing: border-box;
    background: none;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    cursor: pointer;
}

.field_chb label:before{
    display: block;
    content: "";
    text-align: center;
    font-weight: 700;
}

.field_chb_input:checked + label:before {
    content: "\2714";
}

.label_chb{
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding-left: 3px;
    width: calc(100% - 30px);
}

.label_chb a{
    font-weight: 600;
    color: #344054;
    font-size: 14px;
}
/* Поле кода подтверждения */
.field_tr.field_veri_code  {
    margin-left: -17px;
    margin-right: -17px;
}

.field_veri_code .field_td {
    padding: 0 17px 22px 17px;
}

.field_veri_code .field{
    height: 60px;
    padding: 0px 4px;
    border-radius: 16px;
    border: 2px solid #98A7BE;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #100F32;
}
/* Поле селекта */
.field_select_panel{
    margin: 0px -10px 0px -10px;
    text-align: left;
    font-size: 0px;
}

.field_select_panel_item_out{
    display: inline-block;
    vertical-align: top;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
}

.field_select_panel_item{
    padding: 16px;
    height: auto;
    box-sizing: border-box;
    border-radius: 16px;
    background: #F7F8F8;
    font-size: 0px;
    text-align: left;
    cursor: pointer;
}

.field_select_panel_item:hover,
.field_select_panel_item.active
{
    background: #ECF0FE;
}

.field_select_panel_item_img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.field_select_panel_item_img img{
    display: block;
}

.field_select_panel_item_info{
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 120px);
}

.field_select_panel_item_title{
    color: #232323;
    font-weight: 600;
    margin-bottom: 4px;
}

.field_select_panel_item_desc{
    color: #232323;
    font-size: 14px;
}

.field_select_panel_item_inf{
    margin-top: 16px;
    color: #446BF2;
    font-size: 14px;
    line-height: 17px;
}

.field_select_panel_item_inf img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
}
/**/
.field_select_label{
    margin-right: -12px;
    text-align: left;
    font-size: 0px;
}

.field_select_label_item{
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
    margin-bottom: 12px;
    padding: 12px 16px;
    border-radius: 12px;
    background: #F7F8F8;
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.field_select_label_item:hover,
.field_select_label_item.active
{
    background: #ECF0FE;
    color: #051F6C;
}
/* Сообщение формы */
#form_msg{
    border-radius: 12px;
    background: #F4F4F4;
    padding: 12px 23px;
}

.form_msg_title{
    color: #344054;
    font-weight: 500;
    margin-bottom: 8px;
}

.form_msg_text{
    color: #667085;
    margin-right: -15px;
}

.form_msg_text *{
    color: #667085;
}

.form_msg_text ul, .form_msg_text li{
    margin: 0px;
    padding: 0px;
}

.form_msg_text ul{
    padding-left: 20px;
}

.form_msg_text li{
    margin-bottom: 8px;
}

.form_msg_text li:last-child{
    margin-bottom: 0px;
}
/* Таблица */
.table_wrap{
    position: relative;
}

.table_wrap table{
    width: auto;
    border-radius: 16px;
    overflow: hidden;
}

.table_wrap table th, .table_wrap table td{
    padding: 16px 24px;
    text-align: left;
    vertical-align: middle;
    line-height: 18px;
}

.table_wrap .nowrap th, .table_wrap .nowrap td{
    white-space: nowrap;
}

.table_wrap table th{
    background: rgba(62, 108, 244, 0.30);

    color: #100F32;
    font-size: 16px;
    font-weight: 600;
}

.table_wrap table td{
    background-color: #FFFFFF;

    color: #232323;
    font-size: 14px;
    font-weight: 500;
}

.table_wrap table tr:nth-child(even) td{
    background: #F7F8F8;
}

.table_wrap table td *{
    font-size: 14px;
    line-height: 18px;
}
/**/
.table_wrap_v2{

}

.table_wrap.table_wrap_v2 table tr td{
    background: #F9FAFC;
    border-bottom: 1px solid #D0D5DD;
}

.table_wrap_v2 table tr:last-child td{
    border-bottom: none;
}
/**/
.table_wrap table input[type="checkbox"]{
    display: inline-block;
    vertical-align: middle;

    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.table_sort_out{
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
    white-space: nowrap;
    margin-left: 5px;
}

.table_sort{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    color: #ECF0FE;
    font-size: 14px;
}

.table_sort.active{
    color: #446BF2;
}
/**/
.table_wrap_with_chb tr th:nth-child(1),
.table_wrap_with_chb tr td:nth-child(1)
{
    width: 18px;
    padding-right: 0px;
}

.table_wrap_with_chb tr th:nth-child(2),
.table_wrap_with_chb tr td:nth-child(2)
{
    padding-left: 10px;
}
/**/
/* Пейджинация */
.pagination_line{
    padding-top: 40px;
}

.pagination_line:after{
    content: "";
    display: block;
    height: 1px;
    clear: both;
}

.pagination_left{
    float: left;
}

.pagination_right{
    float: right;
}
/**/
.pagination_show{
    font-size: 0px;
}

.pagination_show span{
    display: inline-block;
    vertical-align: middle;
    color: #232323;
    font-size: 14px;
    font-weight: 500;
}

.pagination_show select{
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: transparent;
    padding: 0;

    color: #446BF2;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
/**/
.pagination_nums{
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;

    color: #232323;
    font-size: 14px;
    font-weight: 500;
}

.pagination_links{
    display: inline-block;
    vertical-align: top;
}
/**/
.pagination_prev, .pagination_next{
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.pagination_prev img, .pagination_next img{
    display: block;
}
/**/
.pagination_list{
    display: inline-block;
    vertical-align: top;
    margin: 0px 12px 0px 12px;
}

.pagination_list ul, .pagination_list li{
    margin: 0px;
    padding: 0px;
}

.pagination_list ul{
    display: block;
    font-size: 0px;
}

.pagination_list li{
    display: inline-block;
    vertical-align: top;
    margin: 0px 10px 0px 10px;
}

.pagination_list span{
    display: block;

    color: #232323;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none !important;
    width: 18px;
    text-align: center;
    cursor: pointer;
}

.pagination_list span:hover,
.pagination_list span.active
{
    color: #446BF2;
    border-bottom: 1px solid #446BF2;
}
/*
.pagination_list span{
    color: #232323;
}
 */
/* Модальные окна */
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0.25);
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 999;
}

.modal_content {
    padding: 0px;
    background: transparent;
    border-radius: 0px;
    min-width: 50px;
    max-height: 90%;
}

.modal_content:after{
    content: "";
    display: block;
    height: 10px;
    background: transparent;
}

.modal.active {
    display: flex;
}

.modal_window{
    background-color: #ffffff;
    padding: 0px;
    border-radius: 16px;
    margin-bottom: 15px;
    position: relative;
}

.modal_window_head{
    position: relative;
    border-bottom: 1px solid #E5E5E5;
    padding: 16px 75px 16px 24px;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.modal_window_close{
    position: absolute;
    right: 28px;
    top: 20px;
    cursor: pointer;
}

.modal_window_close img{
    display: block;
}

.modal_window_body{
    padding: 24px;
}

.modal_window_body p{
    margin: 0px 0px 12px 0px;
}

.modal_window_body li{
    margin-bottom: 14px;
    margin-bottom: 8px;
}

.modal_window_footer{
    position: relative;
    border-top: 1px solid #E5E5E5;
    padding: 12px 24px 12px 24px;
    box-sizing: border-box;
}

.modal_window_footer:after{
    content: "";
    display: block;
    height: 1px;
    clear: both;
}

.modal_window_footer_lbtn{
    float: left;
    margin-right: 5px;
}

.modal_window_footer_rbtn{
    float: right;
    margin-left: 5px;
}
/**/
.modal_action .modal_window{
    border: 1px solid #D0D2D8;
}

.modal_action .modal_window{
    padding: 16px 16px 16px 64px;
}

.modal_action_img{
    position: absolute;
    left: 16px;
    top: 20px;
}

.modal_action_img img{
    display: block;
}

.modal_action .modal_window_head {
    border-bottom: none;
    padding: 4px 75px 4px 0px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
}

.modal_action .modal_window_close {
    right: 0px;
    top: 0px;
}

.modal_action  .modal_window_body {
    padding: 0px;
}

.modal_action_text{
    color: #26272E;
    font-size: 16px;
    line-height: 24px;
    padding-right: 75px;
}

.modal_action_btns{
    padding-top: 24px;
    text-align: left;
    font-size: 0px;
}

.modal_action_btns .btn{
    display: inline-block;
    width: auto;
    margin-right: 16px;
}
/**/
.modal_action_del .modal_window_head{
    color: #540902;
}

.modal_action_forbidden .modal_window_head{
    color: #051F6C;
}
/**/
.modal_msg{
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid #D0D2D8;
    background: #FFF;
    font-size: 0px;
}

.modal_msg_img, .modal_msg_text, .modal_msg_close{
    display: inline-block;
    vertical-align: middle;
}

.modal_msg_img{
    position: relative;
}

.modal_msg_img img{
    display: block;
}

.modal_msg_text{
    margin: 0px 24px 0px 16px;
    position: relative;
    max-width: 500px;
}

.modal_msg_close{
    cursor: pointer;
}

.modal_msg_close img{
    display: block;
}
/**/
#modal_msg_list{
    position: fixed;
    z-index: 999;
    right: 30px;
    top: 120px;
}

.modal_msg_item{
    text-align: right;
    margin-bottom: 25px;
}

#modal_msg_list .modal_msg{
    text-align: left;
    display: inline-block;
}
/**/
.modal_info_label{
    float: right;
    font-size: 14px;
}

.modal_info_label span{
    font-weight: 600;
}

.modal_info_title{
    font-weight: 600;
    margin-bottom: 5px;
}

.modal_info_desc{
    color: #98A7BE;
    font-size: 14px;
    margin-bottom: 12px;
}

.modal_info_body{
    position: relative;
}

.modal_info_razd{
    height: 8px;
}
/* Субменю */
.submenu_out{
    position: relative;
    display: none;
}

.submenu_out.active{
    display: block;
}

.submenu{
    position: relative;
}

.submenu_close{
    background-color: transparent;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.submenu_arrow{
    font-size: 0px;
    line-height: 0px;
    text-align: center;
    position: relative;
    z-index: 10001;
}

.submenu_arrow img{
    display: inline-block;
}

.submenu_body{
    background: #26272E;
    border-radius: 12px;
    overflow: hidden;
    text-align: left;
    position: relative;
    z-index: 10001;
}

.submenu_menu{
    padding: 12px 0px;
}

.submenu_menu ul, .submenu_menu li{
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: block;
}

.submenu_menu li{
    margin-bottom: 2px;
}

.submenu_menu li:last-child{
    margin-bottom: 0px;
}

.submenu_menu a{
    display: block;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    padding: 4px 16px 4px 16px;
    white-space: nowrap;
    position: relative;
    text-decoration: none !important;
}

.submenu_menu a:hover,
.submenu_menu li.active a
{
    background: #446BF2;
}

.submenu_menu a.submenu_menu_with_img{
    padding-right: 33px;
}

.submenu_menu a.submenu_menu_with_img img{
    display: block;
    position: absolute;
    right: 12px;
    top: 5px;
}

.submenu_menu_razd{
    margin: 10px 0px 10px 0px;
    height: 1px;
    background: #98A7BE;
}
/* Прелоадер */
.page_preloader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    margin-top: 25px;
    animation: rotate 1s linear infinite
}
.page_preloader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #eceff4;
    animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
/**/
/* Авторизация */
/**/
#p_login_page{
    background-color: #ffffff;
}

#p_login_page_in{
    padding-top: 40px;
    padding-bottom: 40px;
}
/**/
#p_login_header{
    position: relative;
    height: 40px;
    margin-bottom: 60px;
}

#p_login_header_logo{
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 40px;
}

#p_login_header_logo img{
    display: block;
    width: auto;
    height: 100%;
}

#p_login_header_menu{
    position: absolute;
    right: 0px;
    top: 10px;
}

#p_login_header_menu ul, #p_login_header_menu li{
    margin: 0;
    padding: 0;
    list-style: none;
}

#p_login_header_menu ul{
    display: block;
    position: relative;
    font-size: 0px;
}

#p_login_header_menu li{
    display: inline-block;
    vertical-align: middle;
    padding-left: 16px;
    margin-left: 16px;
    border-left: 2px solid #ECF0FE;
}

#p_login_header_menu li:first-child{
    border: none;
}

#p_login_header_menu a{
    font-weight: 500;
    color: #100F32;
}

#p_login_header_menu li:last-child a{
    color: #28A8E9;
}

#p_login_header_menu a:hover{

}
/**/
#p_login_workarea{

}
/**/
#p_login_footer{
    height: 20px;
}
/**/
#p_login_form_title{
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0.36px;
    margin-bottom: 8px;
}

#p_login_form_desc{
    color: #667085;
    text-align: center;
    line-height: 24px;
    margin-bottom: 24px;
}

#p_login_form_desc span{
    color: #446BF2;
}
/**/
#p_login_back{
    display: block;
    position: absolute;
    left: 31px;
    top: 33px;
    cursor: pointer;
}
/**/
#p_login_form_copy{
    color: #667085;
    text-align: center;
    font-weight: 500;
}
/**/
/* Авторизация - Авторизация */
/**/
#p_login_auth{
    position: relative;
}

#p_login_auth:after{
    content: "";
    display: block;
    height: 1px;
    clear: both;
}

#p_login_auth_left{
    float: left;
}

#p_login_auth_right{
    float: right;
}
/**/
#p_login_auth_obg{
    position: relative;
    width: 582px;
    padding-bottom: 34px;
}

#p_login_auth_obg_booble{
    position: relative;
    border-radius: 25px;
    background: #446BF2;
    padding: 8px 16px;
    width: 335px;
    box-sizing: border-box;

    color: #FFF;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.5px;
}

#p_login_auth_obg_fon{
    position: relative;
    width: 542px;
    height: 587px;
    background: transparent url(../img/p_login_auth_fon.svg) 50% 50% no-repeat;
    background-size: contain;
    margin-left: 40px;
}

#p_login_auth_obg_booble:after{
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 22px;
    height: 28px;
    background: transparent url(../img/p_login_auth_booble.svg) 50% 50% no-repeat;
}

#p_login_auth_obg_stool{
    position: absolute;
    left: 0px;
    bottom: 34px;
    width: 219px;
    height: 280px;
    background: transparent url(../img/p_login_auth_stool.svg) 50% 50% no-repeat;
    background-size: contain;
}

#p_login_auth_obg_man{
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 330px;
    height: 700px;
    background: transparent url(../img/p_login_auth_man.svg) 50% 50% no-repeat;
    background-size: contain;
}
/**/
#p_login_auth_form{
    position: relative;
    box-sizing: border-box;
    width: 440px;
    padding: 30px 40px;
    border-radius: 48px;
    border: 1px solid #F9F9F9;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 21px 46px 0px rgba(0, 0, 0, 0.05), 0px 60px 120px 0px rgba(0, 0, 0, 0.04), 0px 187px 112px 0px rgba(0, 0, 0, 0.00), 0px 333px 133px 0px rgba(0, 0, 0, 0.01), 0px 520px 146px 0px rgba(0, 0, 0, 0.00);
    margin-bottom: 110px;
}

#p_login_auth_form_body{
    position: relative;
}

#p_login_auth_form_soc_title{
    color: #344054;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

#p_login_auth_form_soc_list{
    text-align: center;
    font-size: 0px;
    margin: 0px -6px 30px -6px;
}

.p_login_auth_form_soc_item_out{
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 0px 6px 0px 6px;
    box-sizing: border-box;
}

.p_login_auth_form_soc_item{
    display: block;
    height: 44px;
    line-height: 44px;
    border-radius: 12px;
    background: #F9F9F9;
    text-align: center;
}

.p_login_auth_form_soc_item img{
    display: inline-block;
    vertical-align: middle;
    width: auto;
    max-height: 90%;
}

#p_login_auth_form_soc_list_hide{
    width: 0px;
    height: 0px;
    overflow: hidden;
    position: absolute;
}
/**/
/* Авторизация - Регистрация */
/**/
#p_login_reg,
#p_login_rec
{
    position: relative;
    padding-top: 120px;
}

#p_login_reg_fon,
#p_login_rec_fon
{
    position: absolute;
    left: 0px;
    top: 90px;
    width: 542px;
    height: 587px;
    background: transparent url(../img/p_login_reg_fon.svg) 50% 50% no-repeat;
    background-size: contain;
}

#p_login_reg_form,
#p_login_rec_form
{
    position: relative;
    box-sizing: border-box;
    width: 440px;
    border-radius: 48px;
    border: 1px solid #F9F9F9;
    background: #FFF;
    padding: 30px 40px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 21px 46px 0px rgba(0, 0, 0, 0.05), 0px 60px 120px 0px rgba(0, 0, 0, 0.04), 0px 187px 112px 0px rgba(0, 0, 0, 0.00), 0px 333px 133px 0px rgba(0, 0, 0, 0.01), 0px 520px 146px 0px rgba(0, 0, 0, 0.00);
    margin: 0 auto;
    margin-bottom: 135px;
}

#p_login_reg_form_body,
#p_login_rec_form_body
{
    position: relative;
}
/**/
/* Авторизация - Восстановление */
/**/
/* Кабинет */
/**/
#p_account_page{
    background: #F9FAFC;
    min-height: 100%;
}

#p_account_wrap{
    padding-top: 80px;
}
/**/
#p_account_header_line{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    background: #FFF;
    z-index: 999;
}

#p_account_header{
    height: 80px;
}
/**/
#p_account_logo{
    display: block;
    position: absolute;
    left: 0px;
    top: 20px;
    width: auto;
    height: 40px;
}

#p_account_logo img{
    display: block;
    width: auto;
    height: 100%;
}
/**/
#p_account_menu{
    position: absolute;
    left: 230px;
    top: 30px;
}

#p_account_menu ul, #p_account_menu li{
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 0px;
}

#p_account_menu ul{
    display: block;
}

#p_account_menu li{
    display: inline-block;
    vertical-align: top;
    margin-right: 36px;
}

#p_account_menu a{
    display: block;
    font-size: 0px;
}

#p_account_menu a svg{
    display: inline-block;
    vertical-align: top;
}

#p_account_menu a span{
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    font-weight: 600;
}

#p_account_menu li.active a svg path,
#p_account_menu a:hover svg path
{
    stroke: #446BF2;
}

#p_account_menu li.active a span,
#p_account_menu a:hover span
{
    color: #446BF2;
}
/**/
#p_account_upanel{
    position: absolute;
    right: 0px;
    top: 22px;
    font-size: 0px;
    cursor: pointer;
    text-align: right;
}
/**/
#p_account_upanel_user{
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
}

#p_account_upanel_user_photo,
#p_account_upanel_user_name,
#p_account_upanel_user_btn
{
    display: inline-block;
    vertical-align: middle;
}

#p_account_upanel_user_photo{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}

#p_account_upanel_user_photo img{
    display: block;
    width: 100%;
    height: 100%;
}

#p_account_upanel_user_name{
    margin-right: 5px;
}

#p_account_upanel_user_name span{
    font-weight: 600;
}

#p_account_upanel_user_btn{
    cursor: pointer;
}

#p_account_upanel_user_btn img{
    display: block;
}

#p_account_upanel .submenu_out{
    text-align: center;
    /*display: none;*/
}

#p_account_upanel .submenu_out.active{
    /*display: block;*/
}

#p_account_upanel .submenu{
    display: inline-block;
}
/**/
#p_account_workarea_line{
    position: relative;
}

#p_account_workarea{
    padding-top: 40px;
    padding-bottom: 40px;
}

#p_account_workarea_title{
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 30px;
}
/**/
#p_account_workarea_banner_out{
    position: relative;
    margin-bottom: 40px;
}

#p_account_workarea_banner{
    position: relative;
    padding: 20px 40px 20px 40px;
    border-radius: 16px;
    overflow: hidden;
    font-size: 0px;
}

#p_account_workarea_banner_img{
    display: inline-block;
    vertical-align: middle;
    width: 220px;
    font-size: 0px;
    margin-right: 40px;
}

#p_account_workarea_banner_img img{
    display: block;
}

#p_account_workarea_banner_info{
    display: inline-block;
    vertical-align: middle;
    width: 600px;
}

#p_account_workarea_banner_title{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 28px;
}

#p_account_workarea_banner_desc{
    color: #232323;
    font-size: 16px;
}

#p_account_workarea_banner_desc p{
    margin-bottom: 12px;
    font-size: 16px;
}

#p_account_workarea_banner_btn{
    position: absolute;
    right: 60px;
    bottom: 30px;
}

#p_account_workarea_banner_btn .btn{
    padding: 0px 19px;
}

#p_account_workarea_banner_close{
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
}

#p_account_workarea_banner_close img{
    display: block;
    max-width: 100%;
    height: auto;
}
/**/
#p_account_footer_line{
    position: relative;
}

#p_account_footer{
    height: 40px;
}
/**/
.workarea_msg{
    position: relative;
    padding: 16px 24px;
    border-radius: 16px;
    background: #eeeeee;
    margin-bottom: 20px;
    font-size: 0px;
}

.workarea_msg img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.workarea_msg span{
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 40px);
    color: #26272E;
}

.workarea_msg_warning{
    background: #FFE9E9;
}
/**/
.workarea_tools{
    margin-bottom: 24px;
}

.workarea_tools:after{
    content: "";
    display: block;
    height: 1px;
    clear: both;
}

.workarea_tools_left{
    float: left;
}

.workarea_tools_right{
    float: right;
}
/**/
#workarea_tools_menu{
    font-size: 0px;
    text-align: left;
    margin-right: -12px;
}

.workarea_tools_menu_item{
    display: inline-block;
    vertical-align: top;
    border-radius: 12px;
    background: #FFF;
    padding: 0px 16px;
    height: 44px;
    line-height: 43px;
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    position: relative;
}

.workarea_tools_menu_item.active,
.workarea_tools_menu_item:hover
{
    background: #ECF0FE;
    color: #051F6C;
}

.workarea_tools_menu_item img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.workarea_tools_menu_item.workarea_tools_menu_item_only_img img{
    margin-right: 0px;
}
/**/
.tools_item_v1{
    border: 1px solid rgba(62, 108, 244, 0.50);
    background-color: transparent;
}

.tools_item_v2{
    background: #ECF0FE;
    color: #051F6C;
}

.tools_item_v2:hover{
    background: #FFF;
    color: #051F6C;
}

.tools_item_v3{
    background: #446BF2;
    color: #FFFFFF;
}

.tools_item_v3:hover{
    background: #446BF2;
    color: #FFFFFF;
}
/**/
.tools_item_v3_smbtn{
    display: inline-block;
    padding-left: 10px;
    margin-left: 16px;
    border-left: 1px solid #ECF0FE;
    height: 44px;
    line-height: 43px;
}

.tools_item_v3_smbtn img{
    display: inline-block;
    vertical-align: middle;
}
/**/
#workarea_tabs{
    margin-bottom: 40px;
}

#workarea_tabs_menu{
    font-size: 0px;
    text-align: left;
    border-bottom: 1px solid #ECF0FE;
}

#workarea_tabs_menu ul, #workarea_tabs_menu li{
    margin: 0px;
    padding: 0px;
    list-style: none;
}

#workarea_tabs_menu ul{
    display: block;
    font-size: 0px;
    text-align: left;
}

#workarea_tabs_menu li{
    display: inline-block;
    vertical-align: top;
    margin-right: 36px;
    margin-bottom: -1px;
}

#workarea_tabs_menu a{
    display: block;
    padding-bottom: 6px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #ECF0FE;
    text-decoration: none !important;
}

#workarea_tabs_menu a:hover,
#workarea_tabs_menu li.active a
{
    border-bottom: 1px solid #446BF2;
}
/**/
/* Мои скрипты */
/**/
#p_account_scripts{
    position: relative;
}

#p_account_scripts_menu{
    width: 900px;
}

#p_account_scripts_searsh{
    width: 300px;
}

#p_account_scripts_line2{
    position: relative;
}
/**/
#p_account_scripts_list_out{
    overflow: hidden;
    margin-left: -100px;
    margin-right: -100px;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: -60px;
}

#p_account_scripts_list{
    margin: 0px -15px 0px -15px;
    font-size: 0px;
    text-align: left;
    padding-bottom: 60px;
}

.p_account_scripts_item_out{
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 0px 15px 30px 15px;
    box-sizing: border-box;
}

#p_account_scripts_list_null {
    padding: 0px 15px 30px 15px;
}
/**/
.p_account_scripts_item{
    padding: 20px 15px 20px 15px;
    height: 170px;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid #ECF0FE;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(236, 240, 254, 0.30);
    position: relative;
}

.p_account_scripts_item_shop{

}

.p_account_scripts_item_title{
    font-weight: 700;
    overflow: hidden;
    height: 40px;
    margin: 0px 30px 12px 0px;
}

.p_account_scripts_item_shop .p_account_scripts_item_title{
    height: 20px;
    margin: 0px 0px 8px 0px;
}

.p_account_scripts_item_vendor{
    color: #446BF2;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.p_account_scripts_item_svodka{
    font-size: 0px;
    text-align: left;
}

.p_account_scripts_item_svodka_item{
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
}

.p_account_scripts_item_svodka_item img{
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    font-size: 0px;
}

.p_account_scripts_item_svodka_item span{
    display: inline-block;
    vertical-align: top;
    margin-right: 2px;
    color: #98A7BE;
    font-size: 14px;
    font-weight: 500;
}

.p_account_scripts_item_btns{
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 20px;
    font-size: 0px;
}

.p_account_scripts_item_btns .btn{
    padding: 0px;
}

.p_account_scripts_item_btns_left{
    float: left;
    width: 182px;
}

.p_account_scripts_item_btns_right{
    float: right;
    width: 153px;
}

.p_account_scripts_item_shop .p_account_scripts_item_btns_left{
    width: 200px;
}

.p_account_scripts_item_shop .p_account_scripts_item_btns_right{
    width: 135px;
}
/**/
.p_account_scripts_item_mbtn_out{
    position: absolute;
    right: 15px;
    top: 20px;
}

.p_account_scripts_item_mbtn{
    position: relative;
    cursor: pointer;
}

.p_account_scripts_item_mbtn img{
    display: block;
}

.p_account_scripts_item_submenu{
    /*display: none;*/
    position: absolute;
    left: 50%;
    margin-left: -91px;
}

.p_account_scripts_item_submenu.active{
    display: block;
}
/**/
.p_account_scripts_item_add{
    border-radius: 12px;
    border: 2px dashed rgba(68, 107, 242, 0.50);
    background: #F9FAFC;
    box-shadow: none;
    padding-top: 45px;
    cursor: pointer;
}

.p_account_scripts_item_add:hover{
    border: 2px dashed #446BF2;
}

.p_account_scripts_item_add_img{
    text-align: center;
    margin-bottom: 11px;
}

.p_account_scripts_item_add_img img{
    display: inline-block;
}

.p_account_scripts_item_add_title{
    text-align: center;
}
.p_account_scripts_item_add_title span{
    color: #446BF2;
    font-weight: 700;
}
/**/
#p_account_scripts_addform{
    width: 852px;
}

#p_account_scripts_modal_rename{
    width: 652px;
}

#p_account_scripts_modal_dubl{
    width: 652px;
}

#p_account_scripts_modal_remove{
    width: 652px;
}

#p_account_scripts_modal_editrubric{
    width: 352px;
}

#p_account_scripts_modal_download{
    width: 652px;
}
/**/
/* Бонусная программа */
/**/
#p_account_bonuses{
    position: relative;
}
/**/
#p_account_bonuses_actions{
    margin-bottom: 50px;
}

#p_account_bonuses_actions_list{
    margin: 0px -15px 0px -15px;
    font-size: 0px;
    text-align: left;
}

.p_account_bonuses_actions_item_out{
    display: inline-block;
    vertical-align: top;
    width: auto;
    padding: 0px 15px 0px 15px;
    box-sizing: border-box;
}

.p_account_bonuses_actions_item{
    position: relative;
    border-radius: 16px;
    padding: 28px 30px 20px 30px;
    height: 184px;
    box-sizing: border-box;
}

.p_account_bonuses_actions_item1{
    width: 315px;
    background: #ECF0FE url(../img/p_account_bonuses_actions_item1_fon.svg) 50% 50% no-repeat;
    background-size: cover;
}

.p_account_bonuses_actions_item2{
    width: 315px;
    background: #100F32 url(../img/p_account_bonuses_actions_item2_fon.svg) 50% 50% no-repeat;
    background-size: cover;
}

.p_account_bonuses_actions_item3{
    width: 510px;
    background: #232323 url(../img/p_account_bonuses_actions_item3_fon.svg) 50% 50% no-repeat;
    background-size: cover;
}

.p_account_bonuses_actions_label{
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}

.p_account_bonuses_actions_title{
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 10px;
}

.p_account_bonuses_actions_desc{
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}

.p_account_bonuses_actions_desc span{
    color: #446BF2;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.p_account_bonuses_actions_item1 .p_account_bonuses_actions_label{
    color: #100F32;
}

.p_account_bonuses_actions_item1 .p_account_bonuses_actions_title{
    color: #100F32;
}

.p_account_bonuses_actions_item1 .p_account_bonuses_actions_desc{
    color: #232323;
}

.p_account_bonuses_actions_panel{
    position: absolute;
    left: 30px;
    bottom: 20px;
    height: 36px;
    line-height: 35px;
    width: 149px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 12px;
    background: #ECF0FE;
}

.p_account_bonuses_actions_panel a{
    color: #232323;
    font-size: 14px;
    font-weight: 500;
    /*text-decoration: none !important;*/
}

.p_account_bonuses_actions_panel span{
    color: #667085;
    font-size: 14px;
    font-weight: 500;
}

.p_account_bonuses_actions_item1 .p_account_bonuses_actions_panel{
    background: #232323;
}

.p_account_bonuses_actions_item3 .p_account_bonuses_actions_panel{
    width: 450px;
}

.p_account_bonuses_actions_item1 .p_account_bonuses_actions_panel a{
    color: #FFFFFF;
    /*text-decoration: none !important;*/
}

.p_account_bonuses_actions_item3 .p_account_bonuses_actions_panel span,
.p_account_bonuses_actions_item3 .p_account_bonuses_actions_panel a
{
    display: inline-block;
    padding: 0px 14px 0px 14px;
    line-height: 35px;
}

.p_account_bonuses_actions_item3 .p_account_bonuses_actions_panel span{
    color: #667085;
    float: left;
}

.p_account_bonuses_actions_item3 .p_account_bonuses_actions_panel a{
    color: #446BF2;
    float: right;
}

.p_account_bonuses_actions_img{
    position: absolute;
    right: 30px;
    top: 28px
}

.p_account_bonuses_actions_img img{
    display: block;
}
/**/
#p_account_bonuses_rubics{
    margin-bottom: 30px;
}

#p_account_bonuses_rubics_list{
    font-size: 0px;
    text-align: left;
}

.p_account_bonuses_rubics_item{
    display: inline-block;
    vertical-align: top;
    height: 44px;
    line-height: 43px;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    background: #FFF;
    font-size: 14px;
    font-weight: 600;
    color: #232323;
    margin-right: 12px;
    text-decoration: none !important;
}

.p_account_bonuses_rubics_item:hover,
.p_account_bonuses_rubics_item.active
{
    background: #ECF0FE;
    color: #051F6C;
}

#p_account_bonuses_tabs{
    position: relative;
}

.p_account_bonuses_tab{
    position: relative;
    display: none;
}

.p_account_bonuses_tab.active{
    display: block;
}
/**/
#p_account_bonuses_table{
    position: relative;
}

#p_account_bonuses_table table{
    width: 100%;
}
/**/
.p_account_bonuses_table_profit{
    color: #446BF2;
}
/**/
.p_account_bonuses_table_type1{
    color: #446BF2;
}

.p_account_bonuses_table_type2{
    color: #F24444;
}
/**/
.p_account_bonuses_table_status1{
    color: #98A7BE;
}

.p_account_bonuses_table_status2{
    color: #F2B744;
}

.p_account_bonuses_table_status3{
    color: #24D26A;
}
/**/
#p_account_bonuses_modal_about{
    width: 652px;
}
/**/
.p_account_bonuses_modal_info{
    width: 652px;
}
/**/
/* Внутреннее управление */
/**/
#p_manager_page{
    position: relative;
}
/**/
#p_manager_menu{
    margin-bottom: 30px;
}

#p_manager_menu ul, #p_manager_menu li{
    margin: 0px;
    padding: 0px;
    list-style: none;
}

#p_manager_menu ul{
    display: block;
    font-size: 0px;
    text-align: left;
}

#p_manager_menu li{
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
}

#p_manager_menu a{
    display: block;
    padding: 12px 16px;
    border-radius: 12px;
    background: #FFF;
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none !important;
}

#p_manager_menu a:hover,
#p_manager_menu li.active a
{
    background: #ECF0FE;
    color: #051F6C;
}
/**/
.p_manager_table_action{
    display: inline-block;
    cursor: pointer;
}

.p_manager_table_action_edit{
    color: #446BF2;
}

.p_manager_table_action_del{
    color: #F65353;
}

.p_manager_table_action_out{
    text-align: center;
}

.p_manager_table_action_out .p_manager_table_action{
    margin: 0px 5px 0px 5px;
}

.p_manager_table_action_out .p_manager_table_action:hover{
    opacity: 0.75;
}
/**/
/* Внутреннее управление - Пользователи */
/**/
#p_manager_users{

}

#p_manager_users_searsh{
    width: 300px;
}

#p_manager_users_menu{

}
/**/
#p_manager_users_table table{
    width: 100%;
}

.p_manager_users_table_tarif{
    font-weight: 600;
}

.p_manager_users_table_tarif0{
    color: #F65353;
}

.p_manager_users_table_tarif1{
    color: #446BF2;
}
/**/
.p_manager_users_table_status{
    font-weight: 500;
}

.p_manager_users_table_status0{
    color: #cccccc;
}

/**/
#p_manager_users_modal_filtres{
    width: 352px;
}

#p_manager_users_modal_edit{
    width: 652px;
}

#p_manager_users_modal_pass{
    width: 352px;
}

#p_manager_users_modal_accessadd{
    width: 352px;
}

#p_manager_users_modal_tarif{
    width: 352px;
}
/* Личный кабинет */
#p_account_personal{
    position: relative;
}

#p_account_personal_in{
    width: 310px;
    margin: 0 auto;
}

#p_account_personal_photo_line{
    margin-bottom: 15px;
}

#p_account_personal_photo{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

#p_account_personal_photo img{
    display: block;
    width: 100%;
    height: 100%;
}

#p_account_personal_name{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-bottom: 12px;
}

#p_account_personal_email{
    text-align: center;
    margin-bottom: 36px;
}

#p_account_personal_btns{
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    font-size: 0px;
}

#p_account_personal_btns .btn{
    display: inline-block;
    width: auto;
    margin: 0px 12px;
    padding: 0px 16px;
    font-size: 14px;
    color: #232323;
}

#p_account_personal_btns .btn.btn_c1:hover{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
}
/**/
#p_account_personal_passchange{
    width: 360px;
}

#p_account_personal_editdata{
    width: 652px;
}
/**/
/* Внутреннее управление - Промокоды */
#p_manager_codes_tools_left{
    width: 300px;
}

#p_manager_codes_tools_right{
    width: 234px;
}
/**/
#p_manager_codes_table{
    position: relative;
}

#p_manager_codes_table table{
    width: 100%;
}
/**/
.p_manager_codes_table_status_user{
    color: #446BF2;
}
/**/
#p_manager_codes_tools_left1{
    font-size: 0px;
}

#p_manager_codes_tools_left1 .field{
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
    width: 200px;
}
/**/
.p_manager_codes_table_activate_nums{
    color: #446BF2;
}
/**/
#p_manager_codes_modal_edit{
    width: 352px;
}
/**/
/* Внутреннее управление - Шаблоны */
#p_manager_templates{
    position: relative;
}

#p_manager_templates_list{
    margin-left: -10px;
    margin-right: -10px;
    font-size: 0px;
    text-align: left;
}

.p_manager_templates_item_out{
    display: inline-block;
    vertical-align: top;
    padding: 0px 10px 20px 10px;
    width: 50%;
    box-sizing: border-box;
}

.p_manager_templates_item{
    padding: 30px 30px 0px 30px;
    border-radius: 16px;
    background: #FFF;
    height: 585px;
    box-sizing: border-box;
    overflow: hidden;
}

.p_manager_templates_item_in{
    position: relative;
}

.p_manager_templates_item_btns{
    text-align: center;
    font-size: 0px;
}

.p_manager_templates_item_btns .btn{
    display: inline-block;
    width: auto;
    margin: 0px 6px 0px 6px;
}
/**/
.p_manager_templates_item_add{
    cursor: pointer;
}

.p_manager_templates_item_add:hover{
    background: #ECF0FE;
}

.p_manager_templates_item_add_in{
    text-align: center;
    padding-top: 180px;
}

.p_manager_templates_item_add_img{
    font-size: 0px;
    text-align: center;
    margin-bottom: 24px;
}

.p_manager_templates_item_add_img img{
    display: inline-block;
}

.p_manager_templates_item_add_title{
    text-align: center;
    color: #446BF2;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
}
/* Общие настройки */
#p_manager_settings{
    position: relative;
}

#p_manager_settings .btn_tr .btn{
    display: inline-block;
    width: auto;
}
/**/
.form_message{
    padding: 15px 20px;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #eceff4;
}

.form_message_success{
    background: rgb(24, 139, 48, 0.1);
}

.form_message_error{
    background: rgba(255, 0, 0, 0.1);
}

.form_message_fading,
.form_message[fading="true"]
{
    height: auto;
    overflow: hidden;
    animation: fade1 0.5s 2s forwards;
}

.form_message_title{
    font-weight: bold;
    margin-bottom: 5px;
}

.form_message_text *{
    color: #343434;
}

.form_message_text ul {
    margin: 0px;
    padding-left: 20px;
}

.form_message_text li {
    padding: 0px;
    margin: 0px;
}

@keyframes fade1 {
    from {
        opacity: 1;
        position: relative;
    }
    99% {
        opacity: 0;
        position: relative;
    }
    100% {
        opacity: 0;
        position: fixed;
    }
}
/* Редактор НАЧАЛО */
/**/
/* Состояние кнопок */
.p_editor_btn{
    display: block;
    height: 48px;
    line-height: 47px;
    text-align: center;
    border: 1px solid #3E6CF480;
    border-radius: 16px;
    padding: 0 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
    color: #051F6C;
    cursor: pointer;
}

.p_editor_btn:hover{
    border: 1px solid #ECF0FE;
    background: #ECF0FE;
    color: #051F6C;
}

.p_editor_btn:active{
    border: 1px solid #446BF2;
    background: #446BF2;
    color: #ffffff;
}

.p_editor_btn.disabled{
    border: 1px solid #98A7BE80;
    background: #ffffff;
    color: #98A7BE;
}
/* Скролл */
.horizontal_scroll{
    overflow-y: auto;
}

.horizontal_scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.horizontal_scroll::-webkit-scrollbar-track {
    background: #F7F8F8;
}

.horizontal_scroll::-webkit-scrollbar-thumb {
    background-color: #98A7BE;
    border-radius: 2px;
    border: 3px solid #98A7BE;
}
/* Каркас редактора */
#p_editor_page{
    height: 100%;
}

#p_editor_header{
    height: 94px;
    border-bottom: 1px solid #E9EAED;
    box-sizing: border-box;
}

#p_editor_left{
    float: left;
    width: 424px;
    min-width: 424px;
    max-width: 810px;
    height: calc(100% - 94px);
    border-right: 1px solid #E9EAED;
    box-sizing: border-box;
    position: relative;
}

#p_editor_left_draggable{
    float: left;
    width: 5px;
    height: calc(100% - 94px);
    background: transparent;
    cursor: col-resize;
}

#p_editor_center{
    box-sizing: border-box;
    margin: 0 296px 0 424px;
    height: calc(100% - 94px);
}

#p_editor_right{
    float: right;
    width: 296px;
    height: calc(100% - 94px);
    border-left: 1px solid #E9EAED;
    box-sizing: border-box;
}

#p_editor_footer{
    clear: both;
}
/* Подсказки */
.p_editor_tooltip_elem{
    overflow: visible;
    position: relative;
}

.p_editor_tooltip_out{
    position: fixed;
    left: 0;
    top: 0;
    width: 244px;
    z-index: 9999;
    display: none;
}

.p_editor_tooltip_elem:hover .p_editor_tooltip_out{
    display: block;
}

.p_editor_tooltip{
    background: #26272E;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.p_editor_tooltip_out:after{
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background: #26272E;
    margin: -6px auto 0 auto;
    transform: rotate(45deg);
}
/* Шапка редактора */
#p_editor_header_back{
    display: block;
    position: absolute;
    left: 30px;
    top: 31px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #F7F8F8;
    border-radius: 8px;
}

#p_editor_header_back img{
    display: inline-block;
    vertical-align: middle;
}

#p_editor_header_logo{
    display: block;
    position: absolute;
    left: 86px;
    top: 27px;
}

#p_editor_header_logo img{
    display: block;
}

#p_editor_header_name_line{
    position: absolute;
    left: 424px;
    right: 296px;
    top: 36px;
    text-align: center;
}

#p_editor_header_name_text{
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 600;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#p_editor_header_name_down_out{
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
    position: relative;
}

#p_editor_header_name_down{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

#p_editor_header_name_down img{
    display: block;
}

#p_editor_header_name_submenu{
    position: absolute;
    left: 50%;
    margin-left: -91px;
}

#p_editor_header_play{
    display: block;
    position: absolute;
    right: 30px;
    top: 25px;
    height: 44px;
    line-height: 44px;
    padding: 0 15px 0 15px;
    border-radius: 12px;
    background: #446BF2;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}

#p_editor_header_play img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    margin-bottom: 2px;
}

#p_editor_header_play:hover{
    background: #28A8E9;
    text-decoration: none;
}
/* Правая колонка */
#p_editor_right_title{
    height: 84px;
    border-bottom: 1px solid #E9EAED;
    box-sizing: border-box;
    padding: 28px 28px 28px 28px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
}

#p_editor_right_list_out{
    box-sizing: border-box;
    padding: 20px 28px;
    max-height: calc(100% - 84px);
}

#p_editor_right_list{
    position: relative;
}

#p_editor_right_list ul,
#p_editor_right_list li
{
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

#p_editor_right_list li{
    margin-bottom: 15px;
}

#p_editor_right_list a{
    display: inline-block;
    font-weight: 500;
    line-height: 20px;
    color: #232323;
}

#p_editor_right_list li.active a,
#p_editor_right_list a:hover
{
    color: #446BF2;
    text-decoration: none;
}
/* Левая колонка */
/* Поиск */
#p_editor_left_search{
    height: 84px;
    border-bottom: 1px solid #E9EAED;
    box-sizing: border-box;
    padding: 20px 28px 0px 28px;
}

#p_editor_left_search_form{
    position: relative;
}

#p_editor_left_search_form .field {
    padding-left: 40px;
    padding-right: 14px;
    border: 1px solid #F7F8F8;
    background: #F7F8F8;
    color: #667085;
}

#p_editor_left_search_form .field_ico {
    left: 14px;
    right: auto;
}
/* Список карточек */
#p_editor_left_list_out{
    box-sizing: border-box;
    padding: 20px 28px;
    max-height: calc(100% - 84px - 87px);
}

#p_editor_left_list{
    position: relative;
    margin: 0 -10px 0 -10px;
    text-align: left;
    font-size: 0;
}
/* Карточка в списке */
.p_editor_left_item_out{
    display: inline-block;
    vertical-align: top;
    width: 364px;
    padding: 0 10px 20px 10px;
}

.p_editor_left_item{
    background-color: #FFFFFF;
    border: 1px solid #D0D2D8;
    padding: 18px 15px;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
}

.p_editor_left_item_info{
    position: relative;
}

.p_editor_left_item_tools{
    position: relative;
    margin-bottom: 16px;
}

.p_editor_left_item_summary{
    padding-top: 20px;
}

.p_editor_left_item_answers{
    display: none;
}

.p_editor_left_item_separator{
    margin: 0 -15px 0 -15px;
    height: 1px;
    background: #D0D2D8;
}
/**/
.p_editor_left_item_title{
    font-weight: 700;
    margin: 0 30px 12px 0;
}

.p_editor_left_item_desc{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #98A7BE;
    margin: 0 0 16px 0;
}

.p_editor_left_item_check{
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 0;
}

.p_editor_left_item_check img{
    display: block;
}

.p_editor_left_item_check img:nth-child(1){
    display: block;
}

.p_editor_left_item_check img:nth-child(2){
    display: none;
}
/**/
.p_editor_left_item_tools_list{
    font-size: 0;
}

.p_editor_left_item_tools_item{
    display: inline-block;
    vertical-align: top;
    border-radius: 12px;
    background: #CCC;
    padding: 0px 8px;
    min-width: 36px;
    height: 36px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 12px;
    box-sizing: border-box;
    position: relative;
}

.p_editor_left_item_tools_item:hover{
    opacity: 0.75;
}

.p_editor_left_item_tools_item img{
    display: inline-block;
    vertical-align: middle;
}

.p_editor_left_item_tools_item_attach{
    padding: 0px 12px;
    background: #446BF2;
}

.p_editor_left_item_tools_item_edit{
    background: #F7F8F8;
}

.p_editor_left_item_tools_item_del{
    background: #FFF0F0;
}

.p_editor_left_item_tools_index{
    float: right;
    background: #ECF0FE;
    font-size: 16px;
    font-weight: 600;
    color: #082A94;
    margin-right: 0;
}

.p_editor_left_item_tools_index:hover{
    opacity: 1;
}
/**/
.p_editor_left_item_summary_list{
    font-size: 0;
}

.p_editor_left_item_summary_item{
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #98A7BE;
}

.p_editor_left_item_summary_item img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
}

.p_editor_left_item_summary_item_roll{
    float: right;
    cursor: pointer;
    margin-right: 0;
}

.p_editor_left_item_summary_item_roll img{
    margin-left: 2px;
    margin-right: 0;
}

.p_editor_left_item_summary_item_roll:hover{
    opacity: 0.75;
}

.p_editor_left_item_summary_item_roll_down{
    display: inline-block;
}

.p_editor_left_item_summary_item_roll_up{
    display: none;
}
/**/
.p_editor_left_item_answers_list{
    padding-top: 20px;
    margin: 0 -12px -12px 0;
    font-size: 0;
}

.p_editor_left_item_answers_item{
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    max-width: calc(100% - 12px);
    border-radius: 12px;
    background: #ECF0FE;
    padding: 0px 16px;
    height: 36px;
    line-height: 36px;
    color: #082A94;
    font-size: 14px;
    font-weight: 600;
    margin: 0 12px 12px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.p_editor_left_item_answers_item img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
    margin-bottom: 3px;
}

.p_editor_left_item_answers_item:hover{
    /**/
}

.p_editor_left_item_answers_item.disabled{
    color: #98A7BE;
    background: #98A7BE1A;
}

.p_editor_left_item_answers_item.disabled img{
    opacity: 0.5;
}

.p_editor_left_item_answers_item.error{
    background: #F43E3E1A;
}
/**/
.p_editor_left_item_answers_item_new{
    border: 1px dashed #98A7BE;
    background: #ffffff;
    color: #98A7BE;
}

.p_editor_left_item_answers_item_new:hover{
    border: 1px dashed #446BF2;
    color: #446BF2;
    text-decoration: none;
}
/**/
.p_editor_left_item_links_item{

}
/**/
.p_editor_left_item:hover,
.p_editor_left_item.active
{
    border: 1px solid #446BF2;
}

.p_editor_left_item:hover .p_editor_left_item_separator,
.p_editor_left_item.active .p_editor_left_item_separator
{
    background: #446BF2;
}

.p_editor_left_item:hover .p_editor_left_item_check img:nth-child(1),
.p_editor_left_item.active .p_editor_left_item_check img:nth-child(1)
{
    display: none;
}

.p_editor_left_item:hover .p_editor_left_item_check img:nth-child(2),
.p_editor_left_item.active .p_editor_left_item_check img:nth-child(2)
{
    display: block;
}
/**/
.p_editor_left_item.open .p_editor_left_item_summary_item_roll_down{
    display: none;
}

.p_editor_left_item.open .p_editor_left_item_summary_item_roll_up{
    display: inline-block;
}

.p_editor_left_item.open .p_editor_left_item_answers{
    display: block;
}
/* Добавление */
#p_editor_left_add{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 18px 40px;
    border-top: 1px solid #E9EAED;
}

#p_editor_left_add_btn1, #p_editor_left_add_btn2{
    display: inline-block;
    vertical-align: top;
    height: 48px;
    line-height: 48px;
    border: 1px solid #3E6CF480;
    border-radius: 16px;
    text-align: center;
    font-weight: 600;
    color: #051F6C;
}

#p_editor_left_add_btn1 img, #p_editor_left_add_btn2 img{
    display: inline-block;
    vertical-align: middle;
}

#p_editor_left_add_btn1:hover, #p_editor_left_add_btn2:hover{
    border: 1px solid #446BF2;
    text-decoration: none;
}

#p_editor_left_add_btn1{
    width: 280px;
}

#p_editor_left_add_btn2{
    width: 48px;
    float: right;
}
/* Рабочая область */
.p_editor_center_item_out{
    box-sizing: border-box;
    padding: 28px 40px;
    max-height: calc(100% - 1px);
    position: relative;
}

.p_editor_center_item{
    position: relative;
}

.p_editor_center_item_title{
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 45px;
    margin-right: 270px;
}

.p_editor_center_item_desc{
    font-size: 18px;
    line-height: 21px;
    color: #232323;
    margin-bottom: 45px;
}

.p_editor_center_item_desc p{
    font-size: 18px;
    line-height: 21px;
    color: #232323;
    margin-bottom: 15px
}

.p_editor_center_item_back{
    position: absolute;
    right: 40px;
    top: 28px;
    height: 44px;
    line-height: 44px;
    border-radius: 12px;
    padding: 0 16px;
    background: #ECF0FE;
    font-weight: 600;
    color: #051F6C;
}

.p_editor_center_item_back:hover{
    text-decoration: none;
    background: #446BF2;
    color: #ffffff;
}
/* Ответы */
.p_editor_center_item_answers{
    margin-bottom: 30px;
}

.p_editor_center_item_answers_list{
    margin: 0 -10px -20px -10px;
    font-size: 0;
}

.p_editor_center_item_answers_item_out{
    display: inline-block;
    vertical-align: top;
    padding: 0 10px 20px 10px;
    width: 33.33%;
    box-sizing: border-box;
}

.p_editor_center_item_answers_item{

}
/* Предпросмотр */
.p_editor_center_item_preview{
    position: relative;
}

.p_editor_center_item_preview_list{
    position: relative;
    margin: 0 -10px 0 -10px;
    text-align: left;
    font-size: 0;
}
/**/
.p_editor_center_item_skeleton_title{
    display: inline-block;
    width: 215px;
    height: 29px;
    background: #F7F8F8;
    border-radius: 16px;
    margin-bottom: 45px;
}

.p_editor_center_item_skeleton_img{
    font-size: 0;
}

.p_editor_center_item_skeleton_img img{
    display: block;
    width: 100%;
    height: auto;
}
/* Пустой */
.p_editor_left_item_null{
    position: relative;
    text-align: center;
    padding-top: 5px;
}

.p_editor_left_item_null_img{
    font-size: 0;
    line-height: 1;
    margin-bottom: 20px;
}

.p_editor_left_item_null_img img{
    display: inline-block;
}

.p_editor_left_item_null_text{
    font-size: 18px;
    line-height: 20px;
    color: #232323;
}
/**/
.p_editor_center_start{
    width: 875px;
    position: relative;
    margin: 0 auto;
    padding-top: 10px;
}

.p_editor_center_start_picture{
    position: relative;
    margin-bottom: -20px;
    padding-bottom: 100px;
}

.p_editor_center_start_fon{
    position: absolute;
    left: 316px;
    top: 41px;
    width: 542px;
}

.p_editor_center_start_fon img{
    display: block;
    width: 100%;
}

.p_editor_center_start_buble{
    position: relative;
    margin-left: 71px;
    margin-bottom: -10px;
    width: 314px;
    box-sizing: border-box;
    border-radius: 25px;
    background: #448AF7;
    padding: 8px 16px;
    color: #FFF;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.55px;
}

.p_editor_center_start_buble:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 22px;
    height: 28px;
    background: transparent url(../img/p_editor_start_buble.svg) 50% 50% no-repeat;
}

.p_editor_center_start_img{
    margin-left: 322px;
    width: 400px;
    font-size: 0;
}

.p_editor_center_start_img img{
    display: block;
    width: 100%;
    height: auto;
}

.p_editor_center_start_text{
    position: relative;
    background: #ECF0FE;
    border-radius: 16px;
    padding: 16px 24px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #232323;
    margin-bottom: 42px;
}

.p_editor_center_start_btn{
    text-align: center;
}

.p_editor_center_start_btn .btn{
    display: inline-block;
    width: 220px;
}
/**/
.p_editor_modal_form{
    width: 852px;
}
/**/
#p_editor_modal_form_edit .field_with_btn_out .field{
    width: calc(100% - 155px);
}
/**/
.p_editor_roll{
    display: inline-block;
}

.p_editor_roll_elem{
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #98A7BE;
    cursor: pointer;
}

.p_editor_roll_elem img{
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    margin-right: 0;
}

.p_editor_roll_elem:hover{
    opacity: 0.75;
}

.p_editor_roll__open .p_editor_roll_down{
    display: none;
}

.p_editor_roll__close .p_editor_roll_up{
    display: none;
}

.p_editor_roll__close .p_editor_left_item_answers_list{
    display: none;
}
/**/
.p_editor_modal_form .p_editor_left_item_answers_list{
    padding-top: 2px;
}
/**/
.answers_list_edit{
    height: auto;
    padding: 0px 10px;
    font-size: 0px;
    overflow: hidden;
}

.answers_list_edit_item{
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 36px;
    line-height: 36px;
    border-radius: 12px;
    background: #3E6CF41A;
    margin: 4px;
    padding: 0px 49px 0px 16px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    color: #082A94;
    white-space: nowrap;
}


.answers_list_edit input{
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 36px;
    line-height: 36px;
    margin: 4px;
    padding: 0;
    color: #667085;
    border: none;
    background: transparent;
}

.answers_list_edit_item.error{
    background: #F43E3E1A;
}

.answers_list_edit_item_close{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 33px;
    height: 36px;
    line-height: 36px;
    background: #3E6CF44D;
    text-align: center;
    cursor: pointer;
}

.answers_list_edit_item_close img{
    display: inline-block;
    vertical-align: middle;
}
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/* Редактор КОНЕЦ */
/**/
/* Запрет просмотра НАЧАЛО */
/**/
#p_view_stop_page{
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #FFFFFF;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 999;
}

#p_view_stop_content {
    width: 600px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 0px 20px 10px #A0A2A51A;
    text-align: center;
}

#p_view_stop_logo{
    margin-bottom: 56px;
}

#p_view_stop_logo a{
    display: inline-block;
}

#p_view_stop_logo img{
    display: block;
}

#p_view_stop_img{
    margin-bottom: 20px;
}

#p_view_stop_img img{
    display: inline-block;
}

#p_view_stop_title{
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #000000;
}

#p_view_stop_desc{
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 85px;
}

#p_view_stop_btn{
    position: relative;
}

#p_view_stop_btn .btn{
    display: inline-block;
    width: 240px;
}
/**/
/* Запрет просмотра КОНЕЦ */
/**/