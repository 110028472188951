/**/
html,
body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #ffffff;
}

#root{
    height: 100%;
}

div {
    margin: 0px;
    padding: 0px;
    display: block;
}

a {
    text-decoration: none;
    color: #446BF2;
}

a:hover {
    text-decoration: underline;
}

* {
    font-family: 'SF Pro Display', Arial;
    font-weight: normal;
    font-style: normal;

    outline: none;
    color: #100F32;
    font-size: 16px;
    line-height: 20px;
}

p{
    margin: 0px 0px 24px 0px;
}

p sup {
    font-size: 10px;
}

::selection {
    background: #446BF2;
    color: #ffffff;
}

::-moz-selection {
    background: #446BF2;
    color: #ffffff;
}

img {
    border: none;
}

img[src=""] {
    display: none;
}

table {
    border-collapse: collapse;
}

strong, b{
    font-weight: 600;
}
/**/
/* Состояние кнопок */
.p_view_btn{
    display: block;
    height: 48px;
    line-height: 47px;
    text-align: center;
    border: 1px solid #3E6CF480;
    border-radius: 16px;
    padding: 0 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
    color: #051F6C;
    cursor: pointer;
}

.p_view_btn:hover{
    border: 1px solid #ECF0FE;
    background: #ECF0FE;
    color: #051F6C;
}

.p_view_btn:active{
    border: 1px solid #446BF2;
    background: #446BF2;
    color: #ffffff;
}

.p_view_btn.disabled{
    border: 1px solid #98A7BE80;
    background: #ffffff;
    color: #98A7BE;
}
/* Скролл */
.horizontal_scroll{
    overflow-y: auto;
}

.horizontal_scroll::-webkit-scrollbar {
    width: 4px;
}

.horizontal_scroll::-webkit-scrollbar-track {
    background: #F7F8F8;
}

.horizontal_scroll::-webkit-scrollbar-thumb {
    background-color: #98A7BE;
    border-radius: 2px;
    border: 3px solid #98A7BE;
}
/* Каркас редактора */
#p_view_page{
    height: 100%;
}

#p_view_header{
    height: 94px;
    border-bottom: 1px solid #E9EAED;
    box-sizing: border-box;
}

#p_view_left{
    float: left;
    width: 424px;
    height: calc(100% - 94px);
    border-right: 1px solid #E9EAED;
    box-sizing: border-box;
    position: relative;
}

#p_view_center{
    box-sizing: border-box;
    margin: 0 0 0 424px;
    height: calc(100% - 94px);
}
/**/
/* Шапка редактора */
#p_view_header_back{
    display: block;
    position: absolute;
    left: 30px;
    top: 31px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #F7F8F8;
    border-radius: 8px;
}

#p_view_header_back img,
#p_view_header_back svg
{
    display: inline-block;
    vertical-align: middle;
}

#p_view_header_logo{
    display: block;
    position: absolute;
    left: 86px;
    top: 27px;
}

#p_view_header_logo img,
#p_view_header_logo svg
{
    display: block;
}

#p_view_header_name_line{
    position: absolute;
    left: 424px;
    right: 296px;
    top: 36px;
    text-align: center;
}

#p_view_header_name_text{
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 600;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#p_view_header_play{
    display: block;
    position: absolute;
    right: 30px;
    top: 25px;
    height: 44px;
    line-height: 44px;
    padding: 0 15px 0 15px;
    border-radius: 12px;
    background: #446BF2;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}

#p_view_header_play img,
#p_view_header_play svg
{
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    margin-bottom: 2px;
}

#p_view_header_play:hover{
    background: #28A8E9;
    text-decoration: none;
}
/* Левая колонка */
#p_view_left_title{
    height: 84px;
    border-bottom: 1px solid #E9EAED;
    box-sizing: border-box;
    padding: 28px 28px 28px 28px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
}

#p_view_left_list_out{
    box-sizing: border-box;
    padding: 20px 28px;
    max-height: calc(100% - 84px);
}

#p_view_left_list{
    position: relative;
}

#p_view_left_list ul,
#p_view_left_list li
{
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

#p_view_left_list li{
    margin-bottom: 15px;
}

#p_view_left_list a{
    display: inline-block;
    font-weight: 500;
    line-height: 20px;
    color: #232323;
}

#p_view_left_list li.active a,
#p_view_left_list a:hover
{
    color: #446BF2;
    text-decoration: none;
}
/**/
.p_view_center_item_out{
    box-sizing: border-box;
    padding: 28px 40px;
    max-height: calc(100% - 1px);
}

.p_view_center_item{
    position: relative;
}

.p_view_center_item_title{
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 45px;
}

.p_view_center_item_copy{
    display: inline-block;
    vertical-align: middle;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 12px;
    background: #ECF0FE;
    text-align: center;
    margin-left: 15px;
    margin-top: -5px;
}

.p_view_center_item_copy:hover{
    background: #446BF2;
}

.p_view_center_item_copy img,
.p_view_center_item_copy svg
{
    display: inline-block;
    vertical-align: middle;
}

.p_view_center_item_desc{
    font-size: 18px;
    line-height: 21px;
    color: #232323;
    margin-bottom: 45px;
}

.p_view_center_item_desc p{
    font-size: 18px;
    line-height: 21px;
    color: #232323;
    margin-bottom: 15px
}

.p_view_center_item_back{
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    line-height: 44px;
    border-radius: 12px;
    padding: 0 16px;
    background: #ECF0FE;
    font-weight: 600;
    color: #051F6C;
}

.p_view_center_item_back:hover{
    text-decoration: none;
    background: #446BF2;
    color: #ffffff;
}
/* Ответы */
.p_view_center_item_answers{
    margin-bottom: 30px;
}

.p_view_center_item_answers_list{
    margin: 0 -10px -20px -10px;
    font-size: 0;
}

.p_view_center_item_answers_item_out{
    display: inline-block;
    vertical-align: top;
    padding: 0 10px 20px 10px;
    width: 25%;
    box-sizing: border-box;
}

.p_view_center_item_answers_item{

}
/**/